<script>
import {plan as api} from "@/api/admin";
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixin";

export default {
	layout:'admin',
	components:{ComponentsCreate},
	mixins:[MixinIndex],
	data(){
		return {
			title:"日程",
			column_option: {
				status: [{id: 1, title: "正常", type: "success"}, {id: 2, title: "草稿", type: "danger"}],
			},
			import_visible:false,
			export_visible:false,
		}
	},
	computed:{
		import_field(){
			return []
		},
		export_field(){
			return []
		},
		column(){
			return [
				{label:"ID",prop:"id",width: 100,sortable:true},
				{label:"标题",prop:"title", search:true},
				{label:"开始时间",prop:"start_time",type:"datetime"},
				{label:"结束时间",prop:"end_time",type:"datetime"},
				{label:"嘉宾",prop:"speaker",type:"speaker"},
				{label:"创建时间",prop:"create_time",type:'datetime',sortable:true},
				{label:"操作",prop:"action",fixed:'right',type:'action'},
			]
		}
	},
	methods:{
		privateAll(params){
			return api.all(params);
		},
		privateDeleted(row){
			return api.delete({
				id:row.id
			})
		},
		privateExportAll(params){
			return api.all(params)
		},
		privateSaveAll(list){
			return api.import(list)
		},
		privateChangeSort(data){
			return api.changeOrder(data)
		}
	}
}
</script>

<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="all"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}">
						<div class="speaker" v-if="item.type === 'speaker' && scope.row[item.prop]">
							<div class="li" v-for="(v,i) in JSON.parse(scope.row[item.prop])" :key="i"><span v-if="v.username">{{v.username}}</span> <span v-if="v.position">| {{v.position}}</span></div>
						</div>
					</template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="editor(scope.row)">编辑</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary" >删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<components-create ref="createRef" :title="title" @success="all"/>
		<table-import ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
	</main>
</template>

<style lang="scss" scoped></style>
